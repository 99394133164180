<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Organisasi</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#2ecc71"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="dialog.organization = true"
                >
                  <v-icon size="16" color="#2ecc71"> mdi-plus-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Tambah</span>
                </v-btn>

                <v-btn
                  color="#7f8c8d"
                  :disabled="
                    loading ||
                    selected.organization.length <= 0 ||
                    selected.organization[0].disabled
                  "
                  class="text-none"
                  text
                  small
                  @click="
                    setForm();
                    dialog.organization = true;
                  "
                >
                  <v-icon size="16" color="#7f8c8d"> mdi-pencil-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Ubah</span>
                </v-btn>

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.organization.length <= 0"
                  text
                  small
                  @click="dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>

                <v-btn
                  color="#9b59b6"
                  class="text-none"
                  :disabled="loading || selected.organization.length <= 0"
                  text
                  small
                  @click="
                    setForm();
                    fetchDataPublisherOrganization();
                    dialog.publisher = true;
                  "
                >
                  <v-icon size="16" color="#9b59b6"> mdi-account-group </v-icon>
                  <span class="hidden-sm-and-down ml-1">Publisher</span>
                </v-btn>

                <v-btn
                  color="#e74c3c"
                  class="text-none"
                  :disabled="
                    loading ||
                    selected.organization.length <= 0 ||
                    selected.organization[0].disabled
                  "
                  text
                  small
                  @click="
                    setForm();
                    dialog.confirmDelete = true;
                  "
                >
                  <v-icon size="16" color="#e74c3c">
                    mdi-delete-forever
                  </v-icon>
                  <span class="hidden-sm-and-down ml-1">Hapus</span>
                </v-btn>
              </v-btn-toggle>
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#27ae60"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="exportData"
                >
                  <v-icon size="16" color="#27ae60"> mdi-file-excel </v-icon>
                  <span class="hidden-sm-and-down ml-1">Export Excel</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.organization"
              :headers="headers.organization"
              :items="dataGrid.organization"
              :loading="loading"
              :options.sync="options.organization"
              :server-items-length="totalData.organization"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    tile
                    size="32"
                    class="rounded mr-2"
                    color="#f7f7f7"
                  >
                    <span v-if="item.img_url == ''">{{
                      item.name | initial
                    }}</span>
                    <v-img v-else :src="item.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0" style="max-width: 220px">
                    <v-list-item-title
                      class="subtitle-2 grey--text text--darken-1"
                    >
                      {{ item.name }}
                      <v-chip
                        x-small
                        class="font-weight-bold"
                        label
                        style="
                          color: rgb(243 156 18);
                          background-color: rgb(243 156 18 / 10%);
                        "
                        v-if="item.disabled"
                      >
                        Default
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="d-flex"
                      v-if="item.review_average > 0"
                    >
                      <v-rating
                        background-color="#4ab762"
                        color="#4ab762"
                        dense
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        half-icon="mdi-star-half"
                        length="5"
                        readonly
                        size="12"
                        :value="Number(Number(item.review_average).toFixed(1))"
                      ></v-rating>
                      <span class="ml-1">
                        <small
                          ><b>{{ Number(item.review_average).toFixed(1) }}</b
                          >/5</small
                        >
                        <small class="ml-1"
                          >(<b>{{ item.review_count }}</b> ulasan)</small
                        ></span
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-flex" v-else>
                      <small><i>(Belum ada ulasan)</i></small>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.category`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.category == 'Power Merchant'"
                >
                  Power Merchant
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-else-if="item.category == 'Official Partner'"
                >
                  Official Partner
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(149 165 166);
                    background-color: rgb(149 165 166 / 10%);
                  "
                  v-else
                >
                  Personal (Komunitas)
                </v-chip>
              </template>

              <template v-slot:[`item.organization_publisher_count`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="ma-2 font-weight-bold"
                      label
                      style="
                        color: rgb(52 73 94);
                        background-color: rgb(52 73 94 / 10%);
                      "
                      @click="
                        selected.organization = [{ ...item }];
                        setForm();
                        fetchDataPublisherOrganization();
                        dialog.publisher = true;
                      "
                    >
                      {{ item.organization_publisher_count }} Publisher
                    </v-chip>
                  </template>
                  <span>Klik untuk melihat data publisher</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.total_course`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 73 94);
                    background-color: rgb(52 73 94 / 10%);
                  "
                >
                  {{ item.total_course }} Kelas
                </v-chip>
              </template>

              <template v-slot:[`item.created_datetime`]="{ item }">
                {{ item.created_datetime | datetime }}
              </template>

              <template v-slot:[`item.last_update_datetime`]="{ item }">
                {{ item.last_update_datetime | datetime }}
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    selected.organization = [];
                    fetchData();
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.organization"
      v-if="dialog.organization"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          {{ form.id > 0 ? "Ubah" : "Tambah" }} Data Organisasi
          <div class="caption grey--text text--darken-1">
            Lengkapi form dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-0">
          <v-form
            ref="form-organization"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <div class="px-4">
              <ImageUpload v-model="form.img_url" class="mb-4" />

              <div class="subtitle-2">
                Nama Organisasi
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.name"
                :error-messages="validation.name"
                @keyup="validation.name = ''"
                :rules="[
                  (v) => !!v || `Nama organisasi tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 50) ||
                    'Nama organisasi maksimal 50 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Pilih Kategori
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <v-autocomplete
                dense
                :items="categoryData"
                color="#4ab762"
                v-model="form.category"
                :disabled="loading"
                solo
                class="rounded-lg mb-3"
                :error-messages="validation.category"
                @keyup="validation.category = ''"
                @keyup.delete="deleteCategory"
                placeholder="Pilih disini"
              >
              </v-autocomplete>

              <div class="subtitle-2">
                Deskripsi Singkat
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.description_short"
                :error-messages="validation.description_short"
                @keyup="validation.description_short = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '') ||
                    'Deskripsi Singkat Tidak Boleh Kosong',
                  (v) =>
                    (v && v.length >=  50) ||
                    'Deskripsi Singkat minimal 50 Karakter',
                  (v) =>
                    (v && v.length <= 500) ||
                    'Deskripsi Singkat maksimal 500 Karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                Deskripsi Lengkap
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-textarea
                dense
                solo
                rows="5"
                no-resize
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.description"
                :error-messages="validation.description"
                @keyup="validation.description = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '') ||
                    'Deskripsi Singkat Tidak Boleh Kosong',
                  (v) =>
                    (v && v.length >=  50) ||
                    'Deskripsi Singkat minimal 50 Karakter',
                  (v) =>
                    (v && v.length <= 1000) ||
                    'Deskripsi Singkat maksimal 1.000 Karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-textarea>
            </div>

            <div
              style="background: rgba(0, 0, 0, 0.1)"
              class="mb-3 mt-6 px-4 py-2 subtitle-2"
            >
              WEBSITE & SOSIAL MEDIA
            </div>

            <div class="px-4">
              <div class="subtitle-2">
                URL Website
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. https://otodidak.com"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.url_web"
                :error-messages="validation.url_web"
                @keyup="validation.url_web = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                URL Youtube
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. https://youtube.com/otodidak"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.url_youtube"
                :error-messages="validation.url_youtube"
                @keyup="validation.url_youtube = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                URL Facebook
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. https://facebook.com/otodidak"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.url_facebook"
                :error-messages="validation.url_facebook"
                @keyup="validation.url_facebook = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                URL Instagram
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. https://instagram.com/otodidak"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.url_instagram"
                :error-messages="validation.url_instagram"
                @keyup="validation.url_instagram = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                URL Twitter
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. https://twitter.com/otodidak"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.url_twitter"
                :error-messages="validation.url_twitter"
                @keyup="validation.url_twitter = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >{{ form.id > 0 ? "Simpan" : "Tambahkan" }} Organisasi
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.organization = false;
              formReset();
              $refs['form-organization'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Data Organisasi
          <div class="caption grey--text text--darken-1">
            Informasi Data Organisasi
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-0"
          v-if="selected.organization.length > 0"
        >
          <div class="px-4">
            <div class="d-flex flex-column justify-center align-center">
              <v-avatar tile class="rounded-lg mb-2" size="80" color="#ecf0f1">
                <span
                  v-if="selected.organization[0].img_url == ''"
                  style="font-size: 42px"
                  >{{ selected.organization[0].name | initial }}</span
                >
                <v-img v-else :src="selected.organization[0].img_url"></v-img>
              </v-avatar>
              <v-chip
                small
                class="ma-2 font-weight-bold"
                label
                style="
                  color: rgb(241 196 15);
                  background-color: rgb(241, 196, 15 / 10%);
                "
                v-if="selected.organization[0].category == 'Power Merchant'"
              >
                Power Merchant
              </v-chip>
              <v-chip
                small
                class="ma-2 font-weight-bold"
                label
                style="
                  color: rgb(52 152 219);
                  background-color: rgb(52 152 219 / 10%);
                "
                v-else-if="
                  selected.organization[0].category == 'Official Partner'
                "
              >
                Official Partner
              </v-chip>
              <v-chip
                small
                class="ma-2 font-weight-bold"
                label
                style="
                  color: rgb(149 165 166);
                  background-color: rgb(149 165 166 / 10%);
                "
                v-else
              >
                Personal (Komunitas)
              </v-chip>
            </div>

            <div class="my-3">
              <div class="caption">Nama</div>
              <div class="subtitle-2 font-weight-bold">
                {{ selected.organization[0].name | emptyData }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Deskripsi Singkat</div>
              <div class="subtitle-2 font-weight-bold">
                {{ selected.organization[0].description_short | emptyData }}
              </div>
            </div>
            <div class="my-3">
              <div class="caption">Deskripsi Lengkap</div>
              <div class="subtitle-2 font-weight-bold">
                {{ selected.organization[0].description | emptyData }}
              </div>
            </div>
          </div>

          <div
            style="background: rgba(0, 0, 0, 0.1)"
            class="mb-3 mt-6 px-4 py-2 subtitle-2"
          >
            WEBSITE & SOSIAL MEDIA
          </div>

          <div class="px-4">
            <div class="my-3">
              <div class="caption">URL Website</div>
              <div class="subtitle-2 font-weight-bold">
                <a
                  :href="selected.organization[0].url_web"
                  target="_blank"
                  v-if="selected.organization[0].url_youtube.length > 0"
                  >{{ selected.organization[0].url_web }}</a
                >
                <div v-else>-</div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">URL Youtube</div>
              <div class="subtitle-2 font-weight-bold">
                <a
                  :href="selected.organization[0].url_youtube"
                  target="_blank"
                  v-if="selected.organization[0].url_youtube.length > 0"
                  >{{ selected.organization[0].url_youtube }}</a
                >
                <div v-else>-</div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">URL Facebook</div>
              <div class="subtitle-2 font-weight-bold">
                <a
                  :href="selected.organization[0].url_facebook"
                  target="_blank"
                  v-if="selected.organization[0].url_facebook.length > 0"
                  >{{ selected.organization[0].url_facebook }}</a
                >
                <div v-else>-</div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">URL Instagram</div>
              <div class="subtitle-2 font-weight-bold">
                <a
                  :href="selected.organization[0].url_instagram"
                  target="_blank"
                  v-if="selected.organization[0].url_instagram.length > 0"
                  >{{ selected.organization[0].url_instagram }}</a
                >
                <div v-else>-</div>
              </div>
            </div>
            <div class="my-3">
              <div class="caption">URL Twitter</div>
              <div class="subtitle-2 font-weight-bold">
                <a
                  :href="selected.organization[0].url_twitter"
                  target="_blank"
                  v-if="selected.organization[0].url_twitter.length > 0"
                  >{{ selected.organization[0].url_twitter }}</a
                >
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="
              dialog.detail = false;
              publisherOrganization.data = [];
              publisherOrganization.page = 1;
              publisherOrganization.totalPage = 1;
            "
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.publisher"
      v-if="dialog.publisher && selected.organization.length > 0"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Data Publisher
          <div class="caption grey--text text--darken-1">
            Pada Organisasi <b>{{ selected.organization[0].name }}</b>
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-text-field
          dense
          solo
          autocomplete="off"
          color="#4ab762"
          type="text"
          clearable
          clear-icon="mdi-backspace"
          placeholder="Cari nama publisher"
          prepend-inner-icon="mdi-account-search-outline"
          class="rounded-lg mt-4 px-4"
          :disabled="loading"
          v-model="publisherOrganization.search"
        ></v-text-field>

        <div class="text-right px-4 mt-4">
          <v-btn
            text
            x-small
            class="px-0 text-none"
            color="#3498db"
            @click="
              dialog.choosePublisher = true;
              fetchDataPublisherNotOrganization();
            "
            ><v-icon left>mdi-plus</v-icon>Tambahkan Publisher</v-btn
          >
        </div>

        <v-list dense class="pb-0">
          <virtual-list
            style="height: calc(100vh - 220px); overflow-y: auto"
            :data-key="'id'"
            :data-sources="publisherOrganization.data"
            :data-component="publisherOrganization.itemPublisher"
            :extra-props="{ showDelete: selected.organization[0].id > 1 }"
          >
            <div slot="footer" class="text-center my-2">
              <v-chip
                color="white"
                text-color="green"
                v-show="publisherOrganization.loading"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
              <v-btn
                v-show="
                  !publisherOrganization.loading &&
                  publisherOrganization.page !=
                    publisherOrganization.totalPage &&
                  publisherOrganization.totalPage != 0
                "
                @click="onScrollToBottom('publisherInOrganization')"
                depressed
                small
                class="text-none white--text"
                color="green"
                >Tampilkan lagi
                <v-icon right small>mdi-arrow-down</v-icon></v-btn
              >
            </div>
            <div
              slot="footer"
              class="caption text-center"
              v-show="
                publisherOrganization.data.length <= 0 &&
                !publisherOrganization.loading
              "
            >
              Silahkan tambah publisher organisasi ini <br />
              pada tombol tambah diatas.
            </div>
          </virtual-list>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="
              dialog.publisher = false;
              publisherOrganization.data = [];
              publisherOrganization.page = 1;
              publisherOrganization.totalPage = 1;
              publisherOrganization.search = '';
            "
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirmDelete"
      persistent
      no-click-animation
      max-width="340"
      content-class="rounded-lg"
    >
      <v-card
        class="rounded-lg"
        v-if="
          selected.organization.length > 0 &&
          selected.organization[0].total_course <= 0
        "
      >
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Konfirmasi Hapus
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menghapus organisasi <b>{{ form.name }}</b
          >?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doDelete"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, hapus
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="
              dialog.confirmDelete = false;
              formReset();
              validationReset();
            "
            class="text-none"
            width="100"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="rounded-lg"
        v-if="
          selected.organization.length &&
          selected.organization[0].total_course > 0
        "
      >
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Peringatan!
        </v-card-title>
        <v-card-text style="min-height: 80px">
          Tidak dapat menghapus organisasi
          <b>{{ selected.organization[0].name }}</b> karena memiliki
          <b>{{ selected.organization[0].total_course }} Kelas</b>.
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            block
            outlined
            depressed
            rounded
            @click="
              dialog.confirmDelete = false;
              formReset();
              validationReset();
            "
            class="text-none"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirmDeletePublisher"
      persistent
      no-click-animation
      max-width="340"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          Konfirmasi Hapus Publisher
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menghapus publisher
          <b>{{ deletePublisher.name }}</b> dari organisasi
          <b>{{ form.name }}</b
          >?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doDeletePublisher"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, hapus
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.confirmDeletePublisher = false"
            class="text-none"
            width="130"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.choosePublisher"
      v-if="dialog.choosePublisher && selected.organization.length > 0"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Data Publisher
          <div class="caption grey--text text--darken-1">
            Bukan Organisasi <b>{{ selected.organization[0].name }}</b>
          </div></v-card-title
        >
        <v-divider></v-divider>

        <v-text-field
          dense
          solo
          autocomplete="off"
          color="#4ab762"
          type="text"
          clearable
          clear-icon="mdi-backspace"
          placeholder="Cari nama publisher"
          prepend-inner-icon="mdi-account-search-outline"
          class="rounded-lg mt-4 px-4"
          :disabled="loading"
          v-model="choosePublisher.search"
        ></v-text-field>

        <div class="text-right px-4 mt-4 caption">
          Publisher Terpilih
          <b
            >({{
              getChoosePublisher.length == 0
                ? "belum ada"
                : getChoosePublisher.length
            }})</b
          >
        </div>

        <v-list dense class="pb-0">
          <virtual-list
            style="height: calc(100vh - 216px); overflow-y: auto"
            :data-key="'id'"
            :data-sources="choosePublisher.data"
            :data-component="choosePublisher.itemPublisher"
            :extra-props="{ showDelete: false, showCheckbox: true }"
          >
            <div slot="footer" class="text-center my-2">
              <v-chip
                color="white"
                text-color="green"
                v-show="choosePublisher.loading"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
              <v-btn
                v-show="
                  !choosePublisher.loading &&
                  choosePublisher.page != choosePublisher.totalPage &&
                  choosePublisher.totalPage != 0
                "
                @click="onScrollToBottom('publisherNotInOrganization')"
                depressed
                small
                class="text-none white--text"
                color="green"
                >Tampilkan lagi
                <v-icon right small>mdi-arrow-down</v-icon></v-btn
              >
            </div>
            <div
              slot="footer"
              class="caption text-center"
              v-show="
                choosePublisher.data.length <= 0 && !choosePublisher.loading
              "
            >
              Tidak ada data.
            </div>
          </virtual-list>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSavePublisher"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Tambahkan Publisher
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="
              dialog.choosePublisher = false;
              choosePublisher.data = [];
              choosePublisher.page = 1;
              choosePublisher.totalPage = 1;
              choosePublisher.search = '';
            "
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import itemPublisher from "@/components/ItemPublisher";
import VirtualList from "vue-virtual-scroll-list";
export default {
  name: "Organization",
  components: {
    ImageUpload: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
    "virtual-list": VirtualList,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Organisasi",
        disabled: true,
        href: "/organization",
      },
    ],

    loading: false,
    dialog: {
      organization: false,
      detail: false,
      publisher: false,
      confirmDelete: false,
      choosePublisher: false,
      confirmDeletePublisher: false,
    },
    selected: {
      organization: [],
    },
    headers: {
      organization: [
        {
          text: "NAMA ORGANISASI",
          width: "300",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "KATEGORI",
          width: "180",
          align: "center",
          sortable: true,
          value: "category",
        },
        {
          text: "DESKRIPSI SINGKAT",
          width: "300",
          align: "start",
          sortable: true,
          value: "description_short",
        },
        {
          text: "JML PUBLISHER",
          width: "170",
          align: "center",
          sortable: false,
          value: "organization_publisher_count",
        },
        {
          text: "JML KELAS",
          width: "120",
          align: "center",
          sortable: true,
          value: "total_course",
        },
        {
          text: "TGL BUAT",
          width: "180",
          value: "created_datetime",
          align: "center",
        },
        {
          text: "TERAKHIR DIUBAH",
          width: "180",
          value: "last_update_datetime",
          align: "center",
        },
      ],
    },
    dataGrid: {
      organization: [],
    },
    options: {
      organization: {},
    },
    totalData: {
      organization: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    valid: true,
    form: {
      id: "",
      name: "",
      category: "Personal (Komunitas)",
      img_url: "",
      description: "",
      description_short: "",
      url_web: "",
      url_youtube: "",
      url_facebook: "",
      url_instagram: "",
      url_twitter: "",
    },
    validation: {
      id: "",
      name: "",
      category: "",
      img_url: "",
      description: "",
      description_short: "",
      url_web: "",
      url_youtube: "",
      url_facebook: "",
      url_instagram: "",
      url_twitter: "",
    },

    categoryData: [],

    publisherOrganization: {
      search: "",
      searchTimeout: null,
      data: [],
      itemPublisher,
      loading: false,
      page: 1,
      totalPage: 1,
    },

    choosePublisher: {
      search: "",
      searchTimeout: null,
      data: [],
      itemPublisher,
      loading: false,
      page: 1,
      totalPage: 1,
    },

    deletePublisher: {
      id: "",
      name: "",
    },
  }),

  watch: {
    "options.organization": {
      async handler() {
        this.selected.organization = [];
        await this.fetchData();
      },
      deep: true,
    },
    "publisherOrganization.search": {
      handler() {
        if (this.publisherOrganization.searchTimeout)
          clearTimeout(this.publisherOrganization.searchTimeout);
        this.publisherOrganization.searchTimeout = setTimeout(() => {
          this.publisherOrganization.page = 1;
          this.publisherOrganization.totalPage = 1;
          this.fetchDataPublisherOrganization();
        }, 800);
      },
    },
    "choosePublisher.search": {
      handler() {
        if (this.choosePublisher.searchTimeout)
          clearTimeout(this.choosePublisher.searchTimeout);
        this.choosePublisher.searchTimeout = setTimeout(() => {
          this.choosePublisher.page = 1;
          this.choosePublisher.totalPage = 1;
          this.fetchDataPublisherNotOrganization();
        }, 800);
      },
    },
  },

  created() {
    this.$on("onDeletePublisher", (source) => {
      this.deletePublisher.id = source.id;
      this.deletePublisher.name = source.name;

      this.dialog.confirmDeletePublisher = true;
    });
  },

  mounted() {
    this.fetchCategory();
  },

  computed: {
    getChoosePublisher() {
      return this.choosePublisher.data.filter((v) => {
        return v.selected;
      });
    },
  },

  methods: {
    formReset() {
      Object.assign(this.form, {
        id: "",
        name: "",
        category: "Personal (Komunitas)",
        img_url: "",
        description: "",
        description_short: "",
        url_web: "",
        url_youtube: "",
        url_facebook: "",
        url_instagram: "",
        url_twitter: "",
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        id: "",
        name: "",
        category: "",
        img_url: "",
        description: "",
        description_short: "",
        url_web: "",
        url_youtube: "",
        url_facebook: "",
        url_instagram: "",
        url_twitter: "",
      });
    },
    setForm() {
      let selected = this.selected.organization[0];

      Object.assign(this.form, {
        id: selected.id,
        name: selected.name,
        category: selected.category.startsWith("Personal")
          ? "Personal (Komunitas)"
          : selected.category,
        img_url: selected.img_url,
        description: selected.description,
        description_short: selected.description_short,
        url_web: selected.url_web,
        url_youtube: selected.url_youtube,
        url_facebook: selected.url_facebook,
        url_instagram: selected.url_instagram,
        url_twitter: selected.url_twitter,
      });
    },
    deleteCategory() {
      if (!this.form.category) {
        this.form.category = "Personal (Komunitas)";
      }
    },

    async doSave() {
      let isValid = this.$refs["form-organization"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan data organisasi...";

        let url = "admin/organization";
        if (this.form.id > 0) {
          url = "admin/organization/update";
        }

        let response = await this.$post(url, this.form);

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil menyimpan data orgasisasi",
            color: "#2ecc71",
          };
          this.dialog.organization = false;

          this.formReset();
          this.$refs["form-organization"].resetValidation();

          this.validationReset();

          this.selected.organization = [];
          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },
    async doDelete() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menghapus organisasi...";

      let response = await this.$post("admin/organization/delete", this.form);

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menghapus orgasisasi",
          color: "#2ecc71",
        };
        this.dialog.confirmDelete = false;

        this.selected.organization = [];
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },
    async doDeletePublisher() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menghapus publisher...";

      let response = await this.$post(
        "admin/organization/delete_organization_user",
        {
          id: this.form.id,
          user_id: this.deletePublisher.id,
        }
      );

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menghapus publisher pada organisasi",
          color: "#2ecc71",
        };
        this.dialog.confirmDeletePublisher = false;

        this.publisherOrganization.page = 1;
        this.publisherOrganization.totalPage = 1;
        this.publisherOrganization.search = "";
        this.fetchDataPublisherOrganization();
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },
    async doSavePublisher() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menambahkan publisher...";

      let response = await this.$post(
        "admin/organization/add_organization_publisher",
        {
          id: this.form.id,
          user_id_array: this.getChoosePublisher.map((item) => item.id),
        }
      );

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menambahkan publisher pada organisasi",
          color: "#2ecc71",
        };
        this.dialog.choosePublisher = false;
        this.choosePublisher.data = [];
        this.choosePublisher.page = 1;
        this.choosePublisher.totalPage = 1;
        this.choosePublisher.search = "";

        this.publisherOrganization.page = 1;
        this.publisherOrganization.totalPage = 1;
        this.publisherOrganization.search = "";
        this.fetchDataPublisherOrganization();
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },

    async fetchData() {
      this.loading = true;

      let sign =
        this.options.organization.sortDesc &&
        this.options.organization.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.organization.page,
        limit: this.options.organization.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.organization.sortBy.length > 0 &&
        this.options.organization.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.organization.sortBy &&
            this.options.organization.sortBy[0]);
      }

      let response = await this.$get("organization", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.organization = response.results.data;
        this.totalData.organization = parseInt(
          response.results.pagination.total_data
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchDataPublisherOrganization() {
      let params = {
        organization_id: this.selected.organization[0].id,
        page: this.publisherOrganization.page,
        limit: 10,
        sort: "-created_datetime",
      };

      if (
        this.publisherOrganization.search &&
        this.publisherOrganization.search.length > 0
      ) {
        params["name[lse]"] = this.publisherOrganization.search;
      }

      this.publisherOrganization.loading = true;

      let response = await this.$get(
        "admin/organization/publisher_by_organization",
        params
      );

      this.publisherOrganization.loading = false;

      if (response.status == 200) {
        if (this.publisherOrganization.page <= 1) {
          this.publisherOrganization.data = response.results.data;
        } else {
          this.publisherOrganization.data =
            this.publisherOrganization.data.concat(response.results.data);
        }
        this.publisherOrganization.totalPage = parseInt(
          response.results.pagination.total_page
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchDataPublisherNotOrganization() {
      let params = {
        organization_id: this.selected.organization[0].id,
        type: "notin",
        page: this.choosePublisher.page,
        limit: 10,
        sort: "name",
      };

      if (
        this.choosePublisher.search &&
        this.choosePublisher.search.length > 0
      ) {
        params["name[lse]"] = this.choosePublisher.search;
      }

      this.choosePublisher.loading = true;

      let response = await this.$get(
        "admin/user/publisher_by_organization",
        params
      );

      this.choosePublisher.loading = false;

      if (response.status == 200) {
        if (this.choosePublisher.page <= 1) {
          this.choosePublisher.data = response.results.data.map((item) => ({
            ...item,
            selected: false,
          }));
        } else {
          this.choosePublisher.data = this.choosePublisher.data.concat(
            response.results.data.map((item) => ({ ...item, selected: false }))
          );
        }
        this.choosePublisher.totalPage = parseInt(
          response.results.pagination.total_page
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchCategory() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Memuat data kategori...";

      const response = await this.$get(`admin/organization/category`);

      this.$store.state.overlay.show = false;

      if (response.status === 200) {
        this.categoryData = response.results.data;
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data kategori.",
          color: "#f39c12",
        };
      }
    },

    async onScrollToBottom(type = "publisherInOrganization") {
      if (type == "publisherInOrganization") {
        if (this.publisherOrganization.loading) {
          return;
        }

        if (
          this.publisherOrganization.page + 1 <=
          this.publisherOrganization.totalPage
        ) {
          this.publisherOrganization.page++;
          this.fetchDataPublisherOrganization();
        }
      }

      if (type == "publisherNotInOrganization") {
        if (this.choosePublisher.loading) {
          return;
        }

        if (this.choosePublisher.page + 1 <= this.choosePublisher.totalPage) {
          this.choosePublisher.page++;
          this.fetchDataPublisherNotOrganization();
        }
      }
    },

    async exportData() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Mengexport data...";

      let sign =
        this.options.organization.sortDesc &&
        this.options.organization.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.organization.page,
        limit: this.options.organization.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.organization.sortBy.length > 0 &&
        this.options.organization.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.organization.sortBy &&
            this.options.organization.sortBy[0]);
      }

      let response = await this.$get("admin/organization/export_xls", params);

      this.$store.state.overlay.show = false;
      this.$store.state.overlay.text = "";

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil mengexport data.",
          color: "#2ecc71",
        };

        window.open(response.results.data.export_url, "_blank");
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal mengexport data.",
          color: "#f39c12",
        };
      }
    },
  },
};
</script>

<style>
</style>
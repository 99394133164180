<template>
  <div>
    <v-divider></v-divider>
    <v-list-item class="py-0">
      <v-list-item-avatar class="py-0 rounded-lg" tile color="#ecf0f1">
        <span v-if="!source.img_url" style="font-size: 14px">{{
          source.name | initial
        }}</span>
        <v-img v-else :src="source.img_url"></v-img>
      </v-list-item-avatar>
      <v-list-item-content class="py-0">
        <v-list-item-title class="subtitle-2 grey--text text--darken-1">{{
          source.name | emptyData
        }}</v-list-item-title>
        <v-list-item-subtitle class="caption grey--text text--darken-1">{{
          source.email | emptyData
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="showCheckbox">
        <v-checkbox color="green" dense :true-value="true" v-model="source.selected"></v-checkbox>
      </v-list-item-action>
      <v-list-item-action v-if="showDelete">
        <v-btn
          small
          icon
          color="#e74c3c"
          @click.stop="dispatch('Organization', 'onDeletePublisher', source)"
        >
          <v-icon small color="#e74c3c">mdi-delete-forever</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import mixins from "@/mixins";
export default {
  name: "ItemPublisher",

  mixins: [mixins],

  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    showDelete: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showCheckbox: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>